<template>
  <div class="nav">
    <div
      class="navlist"
      :class="{ activte: $route.matched[1].children[id].path === item.path }"
      v-for="item in navlist"
      :key="item.id"
      @click="jump(item)"
    >
      <img
        v-if="$route.matched[1].children[id].path === item.path"
        src="../../../asset/img/person_1.png"
        alt
      />
      <img v-else src="../../../asset/img/person_2.png" alt />
      <span>{{ item.name }}</span>
    </div>
  </div>
</template>

<script setup>
import { t } from '../../../languages';
import { router } from "@/router";
import { ref,onMounted } from "vue";
const props = defineProps({
  navlist: [Array],
});
onMounted(()=> {
  if(router.currentRoute.value.fullPath === '/taskManagement'||router.currentRoute.value.path === '/taskRecord'){
    id.value = 0
  }else if(router.currentRoute.value.fullPath === '/airManagement'){
    id.value = 1
  }else{
    id.value = 2
  }
})
const id = ref(0)
function jump(item) {
  router.push(item.path);
  id.value = item.id;
}
</script>

<style lang='less' scoped>
.nav {
  width: 23.125rem;
  float: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 6.5625rem;
  // overflow: auto;
  background: black;
}
.navlist {
  position: relative;
  width: 11.375rem;
  height: 3.0625rem;
  background: black;
  margin-bottom: 0.5rem;
  flex-shrink: 0;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
  span {
    position: absolute;
    top: 0.625rem;
    left: 1.25rem;
    color: #cccccc;
  }
}
.activte {
  font-size: 1.125rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  text-shadow: 0px 2px 6px #091c3b;
  background: linear-gradient(172deg, #afe0fc 0.68359375%, #ffffff 98.6328125%);
  -webkit-background-clip: text;
}
.navlist:last-child {
  margin-bottom: 0;
}
</style>