<template>
  <div class="container">
    <LeftTopNav :navlist="navlist"></LeftTopNav>
    <router-view>

    </router-view>
  </div>
</template>

<script setup>
import { t } from '../../languages';
import LeftTopNav from "./layout/left-top-nav.vue";
import { ref } from "vue";

let navlist = ref([
  { id: 0, name: t("159"), path: "/taskManagement" },
  { id: 1, name: t("160"), path: "/airManagement" },
  { id: 2, name: t("161"), path: "/allTaskRecord" },
]);
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  background: rgba(0, 28, 49, 0.8);
}
</style>